import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retryWhen, take } from 'rxjs/operators';
import { AddPaymentRequest } from 'src/app/core/models/project/add-payment.model';
import {
  AddDraftExtensionRequest, AddDraftRequest, PutDraftRequest
} from 'src/app/core/models/project/draft.model';
import {
  AddInstructions, PutInstructions
} from 'src/app/core/models/project/instructions.model';
import {
  AddSupervisorToProject,
  DismissWriterRequest
} from 'src/app/core/models/project/project-detail.model';
import { QueryParamsModel } from 'src/app/shared/base/models';
import { environment as env } from 'src/environments/environment';
import {
  Project, ProjectCancel, Roles, SearchResult, User
} from '../../models';
import { ManagerApprovalResponse } from '../../models/project/manager-approval-response';
import { ProjectFeedback } from '../../models/project/project-feedback.model';

import { BehaviorSubject } from 'rxjs';
import { result } from "lodash";
import {AuthService} from "../auth/auth.service";



const API: any = {
  assign: 'assign',
  cancel: 'cancel',
  cancellationPenaltyScoreRange: 'cancellation-penalty-score-range',
  drafts: 'drafts',
  extensions: 'extensions',
  feedbacks: 'feedbacks',
  instructions: 'instructions',
  projects: 'projects',
  payments: 'payments',
  paymentDetails: 'payment-details',
  reject: 'reject',
  users: 'users',
  status: 'status',
  transfer: 'transfer',
  writers: 'writers',
  supervisorWorkload: 'reports/supervisor-workload',
  supervisorHistory: 'supervisor-history',
  interestedSupervisors: 'interested-supervisors',
  supervisors: 'supervisor',
  writerEarnings: 'writer-earnings',
  undo: 'undo',
  undoCancel: 'undo-cancel',
  backToInProgress: 'back-to-in-progress',
  backToCompleted: 'back-to-completed',
  requestManagerApproval: 'request-manager-approval',
  deliver: 'deliver',
  dropbox: 'dropbox',
  awaitingClarification: 'awaiting-clarification',
  clarificationProvided: 'clarification-provided',
  readyToStart: 'ready-to-start',
  infoRequired: 'info-required',
  notConfirmed: 'not-confirmed',
  actions: 'actions',
  requestQuotation: 'request-quotation',
  awaitingPayment: 'awaiting-payment',
  pending: 'pending',
  quotations: 'quotations',
  current: 'current',
  calculate: 'calculate-price',
  collectionPlan: 'collection-plans',
  collections: 'collections',
  paymentLink: 'payment-link',
  confirm: 'confirm',
  undoConfirm: 'undo-confirm',
  documentData:'document-data',
  history: 'history',
  respondManagerApproval:'respond-manager-approval',
  folder:'folder',
};

@Injectable({ providedIn: 'root' })
export class ProjectService {

  private costs = new BehaviorSubject([]);
  sharedCosts = this.costs.asObservable();

  constructor(private readonly http: HttpClient) { }

  nextCost(costs: any[]) {
    this.costs.next(costs);

  }
  addInterestedSupervisors(projectId: number, request: AddSupervisorToProject) {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.interestedSupervisors}/${request.supervisorId}`,
      request
    );
  }
  deleteInterestedSupervisors(projectId: number, supervisorId: number) {
    return this.http.delete<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.interestedSupervisors}/${supervisorId}`
    );
  }
  getInterestedSupervisors(projectId: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.interestedSupervisors}`
    );
  }

  /******/

  getMyProjects(query: QueryParamsModel): Observable<SearchResult<Project>> {
    // query.filter.push('statusId==4');

    return this.http
      .get<any>(env.apiUrl + API.projects + query.toUrlParams())
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getQuickFilterCount(filter: any[], user: User): Observable<SearchResult<Project>> {
    const query = new QueryParamsModel();
    query.offset = 0;
    query.limit = 0;

    query.filter.push('statusId==' + filter.map(item => item.id).join(';'));

    if (user.role === 'Supervisor') {
      query.filter.push(`supervisor.id==${user.id}`);
    }

    if (user.role === 'CSA') {
      query.filter.push(`csa.id==${user.id}`);
    }

    return this.http.get<any>(env.apiUrl + API.projects + query.toUrlParams());
  }

  getProjectFeedback(
    id: number,
    query: QueryParamsModel
  ): Observable<SearchResult<ProjectFeedback>> {
    return this.http.get<any>(
      `${env.apiUrl}${API.writers}/${id}/${API.feedbacks}${query.toUrlParams()}`
    );
  }

  getNewProjects(query: QueryParamsModel): Observable<SearchResult<Project>> {
    // query.filter.push('isAssigned==false');
    return this.http
      .get<any>(env.apiUrl + API.projects + query.toUrlParams())
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
  }

  getSupervisors(): Observable<any> {
    let params = new HttpParams();
    params = params.append('filter', `roleId==${Roles.Supervisor}`);
    params = params.append('all', `true`);
    return this.http.get<any>(env.apiUrl + API.users, { params });
  }

  getWriterList(): Observable<any> {
    let params = new HttpParams();
    params = params.append('all', `true`);
    return this.http.get<any>(env.apiUrl + API.writers, { params });
  }

  getWriterEarnings(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.writerEarnings}`
    );
  }

  deleteWriterEarning(projectId: number, itemId: number): Observable<any> {
    return this.http.delete<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.writerEarnings}/${itemId}`
    );
  }

  postWriterEarning(projectId: number, request: any): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.writerEarnings}`,
      request
    );
  }

  updateWriterEarning(item: any, request: any): Observable<any> {

    return this.http.put<any>(`${env.apiUrl}${API.projects}/${item.projectId}/${API.writerEarnings}/${item.id}`, request);
  }

  postPayment(projectId: number, request: AddPaymentRequest): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.payments}`,
      request
    );
  }

  putPayment(
    projectId: number,
    paymentDetailId: number,
    request
  ): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.paymentDetails}/${paymentDetailId}`,
      request
    );
  }

  deletePayment(projectId: number, paymentDetailId: number): Observable<any> {
    return this.http.delete<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.paymentDetails}/${paymentDetailId}`
    );
  }

  getProjectDetails(projectId: number): Observable<any> {
    return this.http.get<any>(`${env.apiUrl}${API.projects}/${projectId}`);
  }

  getDrafts(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.drafts}`
    );
  }

  postDraft(projectId: number, request: AddDraftRequest): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.drafts}`,
      request
    );
  }

  putDraft(
    projectId: number,
    draftId: number,
    request: PutDraftRequest
  ): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.drafts}/${draftId}`,
      request
    );
  }

  postDraftExtension(
    projectId: number,
    draftId: number,
    request: AddDraftExtensionRequest
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.drafts}/${draftId}/${API.extensions}`,
      request
    );
  }

  getInstructions(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.instructions}`
    );
  }

  postInstructions(
    projectId: number,
    request: AddInstructions
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.instructions}`,
      request
    );
  }

  putInstructions(
    projectId: number,
    instructionId: number,
    request: PutInstructions
  ): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.instructions}/${instructionId}`,
      request
    );
  }

  putProjectDetail(projectId: number, request): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.projects}/${projectId}`,
      request
    );
  }

  getWriters(writerId: number): Observable<any> {
    return this.http.get<any>(`${env.apiUrl}${API.writers}/${writerId}`);
  }

  getProjectWriters(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.writers}`
    );
  }

  deleteProjectWriters(
    projectId: number,
    writerId: number,
    request: DismissWriterRequest
  ) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };

    return this.http.delete<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.writers}/${writerId}`,
      options
    );
  }

  postProjectWritersAssign(
    projectId: number,
    writerId: number
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.writers}/${writerId}`,
      1
    );
  }

  postSupervisorToProject(
    projectId: number,
    request: AddSupervisorToProject
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.supervisors}/${request.supervisorId}`,
      request
    );
  }

  postSupervisorTransferToProject(projectId: number, request): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.supervisors}/${request.supervisorId}`,
      request
    );
  }

  putSupervisorTransferToProject(projectId: number, request): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.supervisors}/${request.supervisorId}`,
      request
    );
  }

  getProjectSupervisorHistory(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.supervisorHistory}`
    );
  }

  postProjectCancel(projectId: number, request: ProjectCancel): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.cancel}`,
      request
    );
  }

  postProjectDeliver(projectId: number): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.deliver}`, null);
  }


  postProjectUndo(
    projectId: number,
    request: any
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.undo}`,
      request
    );
  }

  postProjectUndoCancel(
    projectId: number,
    request: any
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.undoCancel}`,
      request
    );
  }

  postProjectBackToInProgress(
    projectId: number,
    request: any
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.backToInProgress}`,
      request
    );
  }

  postProjectBackToCompleted(
    projectId: number,
    request: any
  ): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.backToCompleted}`,
      request
    );
  }

  getCancellationPenaltyScoreRange(): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.cancellationPenaltyScoreRange}`
    );
  }

  getSupervisorWorkload(startDate: string, weekCount: number = 4, selectedMainArea: any = null) {
    if (selectedMainArea !== null && selectedMainArea !== undefined) {
      if (selectedMainArea.id !== null) {
        return this.http
          .get<any>(
            `${env.apiUrl}${API.supervisorWorkload}?startDate=${startDate}&weekCount=${weekCount}&mainAreaId==${selectedMainArea.id}`
          )
          .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
      } else {
        return this.http
          .get<any>(
            `${env.apiUrl}${API.supervisorWorkload}?startDate=${startDate}&weekCount=${weekCount}`
          )
          .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
      }
    } else {
      return this.http
        .get<any>(
          `${env.apiUrl}${API.supervisorWorkload}?startDate=${startDate}&weekCount=${weekCount}`
        )
        .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(10))));
    }
  }

  putProjectStatus(projectId: number, request): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.status}`,
      request
    );
  }
  getProjectStatus(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.status}`
    );
  }

  deleteDraft(projectId: number, draftId: number): Observable<any> {
    return this.http.delete<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.drafts}/${draftId}`
    );
  }

  deleteInstruction(projectId: number, instructionId: number): Observable<any> {
    return this.http.delete<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.instructions}/${instructionId}`
    );
  }

  postManagerApprovalRespond(projectId: number, request: ManagerApprovalResponse): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.respondManagerApproval}`,
      request
    );
  }


  updateDropboxLink(projectId: number, request): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.dropbox}`,
      request
    );
  }

  getActions(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.actions}`
    );
  }

  postAwaitingClarification(projectId: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.awaitingClarification}`,
      options
    );
  }

  postClarificationProvided(projectId: number): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.clarificationProvided}`,
      ''
    );
  }

  postReadyToStart(projectId: number): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.readyToStart}`,
      null
    );
  }

  postInfoRequired(projectId: number, request): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.infoRequired}`,
      request
    );
  }

  postRequestManagerApproval(projectId: number, request): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.requestManagerApproval}`,
      request
    );
  }

  postNotConfirmed(projectId: number): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.notConfirmed}`,
      null
    );
  }

  postRequestQuotation(projectId: number): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.requestQuotation}`,
      null
    );
  }

  postAwaitingPayment(projectId: number): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.awaitingPayment}`,
      null
    );
  }

  postPending(projectId: number, request): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.pending}`,
      request
    );
  }

  postQuotations(projectId: number, request): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.quotations}`,
      request
    );
  }

  getQuotationsHistory(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.quotations}`
    );
  }

  getCurrentQuotations(projectId: number): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${projectId}/${API.quotations}/${API.current}`
    );
  }

  getCalcPrice(details): Observable<any> {
      return this.http.get<any>(`${env.apiUrl}${API.projects}/${API.calculate}`, {params: details})
  }

  postCollectionPlans(customerId: number, request): Observable<any> {
    return this.http.post<any>(
      `${env.apiUrl}${API.collectionPlan}`, request
    );
  }

  editCollectionPlans(collectionId: number, request): Observable<any> {
    return this.http.put<any>(
      `${env.apiUrl}${API.collectionPlan}/${collectionId}`, request
    )
  }

  getCollectionsSearch(query): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.collectionPlan}${query.toUrlParams()}`
    );
  }

  getCollections(id): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.collectionPlan}/${id}`
    )
  }

  getCollectionPlanActions(id): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.collectionPlan}/${id}/${API.actions}`
    );
  }

  getPaymentLink(collectionId, planId): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.collectionPlan}/${collectionId}/${API.collections}/${planId}/${API.paymentLink}`
    );
  }

  putConfirmPlan(planId) {
    return this.http.put<any>(
        `${env.apiUrl}${API.collectionPlan}/${planId}/${API.confirm}`, ''
    )
  }

  putUndoConfirmPlan(planId) {
    return this.http.put<any>(
        `${env.apiUrl}${API.collectionPlan}/${planId}/${API.undoConfirm}`, ''
    )
  }

  putPlanReadyToStart(planId): Observable<any> {
    return this.http.put<any>(
        `${env.apiUrl}${API.collectionPlan}/${planId}/${API.readyToStart}`, ''
    )
  }

  putPlanAwaitingPayment(planId): Observable<any> {
    return this.http.put<any>(
        `${env.apiUrl}${API.collectionPlan}/${planId}/${API.awaitingPayment}`, ''
    )
  }

  deletePlan(planId) {
    return this.http.delete<any>(
        `${env.apiUrl}${API.collectionPlan}/${planId}/${API.cancel}`
    )
  }

  getQuoteDocument(refId) {
    return this.http.get<any>(
        `${env.apiUrl}${API.collectionPlan}/${refId}/${API.documentData}`
    )
  }


  updateCollectionStatus(collectionPlanId, collectionId, request) {
    return this.http.put<any>(
        `${env.apiUrl}${API.collectionPlan}/${collectionPlanId}/${API.collections}/${collectionId}`, request
    )
  }

  getPlanHistory(collectionPlanId): Observable<any> {
    return this.http.get<any>(
        `${env.apiUrl}${API.collectionPlan}/${collectionPlanId}/${API.history}`
    )
  }

  getProjectFolder(referenceNumber): Observable<any> {
    return this.http.get<any>(
      `${env.apiUrl}${API.projects}/${referenceNumber}/${API.folder}`
    )
  }

}
